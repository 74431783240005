import Events from "../pages/events";
import { graphql } from "gatsby";

export default Events;

export const pageQuery = graphql`
query TemplateEventsPage($skip: Int) {
  # Get events
  events: allMarkdownRemark (
    sort: { order: DESC, fields: [frontmatter___date]}, 
    filter: {
      frontmatter: {draft: {ne: true}},
      fileAbsolutePath: { regex: "/events/" }
    },
    limit: 10,
    skip: $skip
  ) {
    totalCount
    edges {
      node {
        fileAbsolutePath
        rawMarkdownBody
        fields {
          slug
        }
        frontmatter {
          title
          date
        }
      }
    }
  }
}
`;
